import React, { useState, useEffect, useRef } from 'react'
import Slider from 'react-slick'
import PropTypes from 'prop-types'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const SignUpFade = ({ autoplay = false, activeIndex, slides }) => {
  const [currentSlide, setCurrentSlide] = useState(0)
  const sliderRef = useRef(null)
  const settings = {
    fade: true,
    infinite: true,
    autoplay,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    swipe: false,
    beforeChange: (_, newIndex) => {
      setCurrentSlide(newIndex)
    }
  }

  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(activeIndex)
    }
  }, [activeIndex])

  const goToSlide = (index) => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(index)
    }
  }

  return (
    <div style={{ width: '100%' }}>
      <Slider ref={sliderRef} {...settings}>
        {slides.map((item, index) => (
          <div className="w-full !flex items-center gap-5" key={index}>
            <img src={item.imageSrc} alt={item.imageAlt} height="75" width="150" />
            <div className="flex flex-col py-3 gap-5">
              <p className="h5-subtext-medium text-black-90">{item.content}</p>
              <p className="h5-tiny-text-regular text-black-70">{item.jobTitle}</p>
            </div>
          </div>
        ))}
      </Slider>

      <div className="flex justify-center gap-1 mt-7">
        {slides.map((_, index) => (
          <button
            key={index}
            onClick={() => goToSlide(index)}
            className={`
              w-5 h-1 rounded-[2px]
              ${currentSlide === index ? 'bg-black-90' : 'bg-black-20'}
              hover:bg-black-60 transition-colors
            `}
          ></button>
        ))}
      </div>
    </div>
  )
}

SignUpFade.propTypes = {
  autoplay: PropTypes.bool,
  activeIndex: PropTypes.number,
  slides: PropTypes.array
}

export default SignUpFade
