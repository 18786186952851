import React, { useState, useEffect, useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import { graphql, navigate, Link } from 'gatsby'
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import { useForm, Controller } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import Cookies from 'js-cookie'
import { connect } from 'react-redux'
import { FormControl, IconButton, InputAdornment, TextField, Divider } from '@mui/material'

import AuthLayout from 'layouts/auth'
import Seo from 'components/seo'
import Icon from 'components/icon'
import SignUpCarousel from 'components/carousel/signUpFade'
import Captcha from 'components/captcha/index'
// import LangSwitcher from 'components/langSwitcher/popover'

import { getPortalHost, sendDataLayerEvent } from 'utils'

import { links, slideItems } from '/static/data/signup.static'
import business from '/static/images/pricing/business_plan.png'
import developer from '/static/images/pricing/developer_plan.png'
import aws from '/static/images/cdn-logo/Cloudfront.png'
import gma from '/static/images/cdn-logo/GA.png'
import vnCdn from '/static/images/cdn-logo/VNCDN.png'

const SignupForm = ({ actionProp: { setMessageDialogOpen, setErrorMessageI18n } }) => {
  const { t, i18n } = useTranslation()
  const { screenM } = useBreakpoint()

  const CustomTransComponents = ({ links = [] }) => ({
    href: (
      <React.Fragment>
        {links?.map((link, linkIndex) => (
          <a
            key={linkIndex}
            id={link.clickID}
            href={link.href}
            className="underline cursor-pointer text-blue-200"
            target="_blank"
            rel="noreferrer"
          >
            {t(link.text)}
          </a>
        ))}
      </React.Fragment>
    )
  })

  const [showPassword, setShowPassword] = useState(false)
  const handleClickShowPassword = () => setShowPassword((show) => !show)
  const [isActionLoading, setIsActionLoading] = useState(false)
  const [isNeedCaptcha, setIsNeedCaptcha] = useState(false)
  const [isReGetCaptcha, setIsReGetCaptcha] = useState(false)
  const [captchaToken, setCaptchaToken] = useState('')

  const captchaValidation = isNeedCaptcha ? yup.string().required('Field is required') : yup.string()

  const schema = yup.object().shape({
    firstName: yup.string().required(t('errorMessages.isRequired', { field: t('signUpPage.form.0') })),
    lastName: yup.string().required(t('errorMessages.isRequired', { field: t('signUpPage.form.1') })),
    email: yup
      .string()
      .required(t('errorMessages.isRequired', { field: t('signUpPage.form.2') }))
      .email(t('errorMessages.invalidFormat', { field: t('signUpPage.form.2') })),
    password: yup
      .string()
      .required(t('errorMessages.isRequired', { field: t('signUpPage.form.3') }))
      .min(8, t('errorMessages.isMinCharacters', { field: t('signUpPage.form.3'), count: 8 }))
      .matches(/[A-Z]/, t('errorMessages.isAtLeastOneUppercaseLetter', { field: t('signUpPage.form.3') }))
      .matches(/[a-z]/, t('errorMessages.isAtLeastOneLowercaseLetter', { field: t('signUpPage.form.3') }))
      .matches(/\d/, t('errorMessages.isAtLeastOneNumber', { field: t('signUpPage.form.3') })),
    captchaInput: captchaValidation
  })

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch
  } = useForm({
    resolver: yupResolver(schema)
  })
  const password = watch('password', '')

  // 使用 useState 跟踪密碼強度規則
  const [strengthRules, setStrengthRules] = useState({
    hasMinLength: false,
    hasNumber: false,
    hasLowercase: false,
    hasUppercase: false
  })

  const validatePasswordStrength = () => {
    // 密碼強度檢查邏輯
    const hasMinLength = password.length >= 8
    const hasUppercase = /[A-Z]/.test(password)
    const hasLowercase = /[a-z]/.test(password)
    const hasNumber = /\d/.test(password)

    // 更新密碼強度規則的有效性
    setStrengthRules({
      hasMinLength,
      hasNumber,
      hasLowercase,
      hasUppercase
    })
  }

  const handleSignup = async (data) => {
    setIsActionLoading(true)
    try {
      const apiUrl = process.env.GATSBY_MLY_API_URL
      const hubspotToken = Cookies.get('hubspotutk') || null

      const payload = {
        action: 'commonRegister',
        ...data,
        hubspotToken
      }
      if (isNeedCaptcha) payload.captchaToken = captchaToken

      const response = await fetch(`${apiUrl}/auth/v2/auth/register/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      })

      const json = await response.json()

      if (!response.ok) {
        const error = new Error(json.meta.message)
        error.response = { ...json.meta }
        throw error
      }

      const { customerId, organizationId, jwtToken } = json.data

      Cookies.set('jwtToken', jwtToken, { secure: true })
      Cookies.set('customerId', customerId, { secure: true })
      Cookies.set('organizationId', organizationId, { secure: true })

      // send GTM event
      const eventPayload = {
        event: 'sign_up',
        method: 'email',
        user_id: organizationId,
        email: data.email
      }

      sendDataLayerEvent(eventPayload)

      navigate('/add-payment/')
    } catch (error) {
      if (error?.response?.code === 1521) {
        setIsNeedCaptcha(true)
        setIsReGetCaptcha(true)
      } else {
        const errorMessage = i18n.exists(`errorMessages.${error?.response?.code}`)
          ? `errorMessages.${error?.response?.code}`
          : 'errorMessages.signUp'
        setErrorMessageI18n(errorMessage)
        setMessageDialogOpen(true)
      }
    } finally {
      setIsActionLoading(false)
    }
  }

  const handleSignupWithGoogle = () => {
    Cookies.remove('jwtToken') // clear jwtToken 才能跑callback error
    const googleOauthConfig = {
      scope: 'email profile openid',
      prompt: 'consent',
      responseType: 'token id_token',
      state: {
        from: 'signup'
      }
    }
    const { scope, prompt, responseType } = googleOauthConfig
    const state = JSON.stringify(googleOauthConfig.state)

    const nonce = `
      ${Math.random().toString(36).substring(2, 15)}
      ${Math.random().toString(36).substring(2, 15)}
    `
    window.location.href = window.encodeURI(
      `https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?
        client_id=${process.env.GATSBY_OAUTH_GOOGLE_CLIENT_ID}&
        scope=${scope}&
        state=${state}&
        prompt=${prompt}&
        response_type=${responseType}&
        ux_mode=redirect&
        redirect_uri=${process.env.GATSBY_OAUTH_GOOGLE_CALLBACK}&
        nonce=${nonce}&
        include_granted_scopes=true`
        .replace(/\n/g, '')
        .replace(/\s{2,}/g, '')
    )
  }

  useEffect(() => {
    validatePasswordStrength()
  }, [password])

  return (
    <AuthLayout hasNav={false} hasFooter={false}>
      <React.Fragment>
        {/* Signup Form */}
        <form onSubmit={handleSubmit(handleSignup)} className="flex items-center">
          <FormControl
            sx={{
              margin: '0 auto',
              width: '100%'
            }}
          >
            <div className="flex items-center justify-between gap-3">
              {/* First name */}
              <Controller
                name="firstName"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    autoComplete="off"
                    label="First name"
                    variant="standard"
                    size="small"
                    sx={{
                      mb: errors.firstName ? 1 : 3,
                      flex: 1
                    }}
                    error={!!errors.firstName}
                    helperText={errors.firstName?.message}
                  />
                )}
              />

              {/* Last name */}
              <Controller
                name="lastName"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    autoComplete="off"
                    label="Last name"
                    variant="standard"
                    size="small"
                    sx={{
                      mb: errors.lastName ? 1 : 3,
                      flex: 1
                    }}
                    error={!!errors.lastName}
                    helperText={errors.lastName?.message}
                  />
                )}
              />
            </div>
            {/* Email */}
            <Controller
              name="email"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  autoComplete="off"
                  label="Email"
                  variant="standard"
                  type={'email'}
                  size="small"
                  sx={{
                    mb: errors.email ? 1 : 3
                  }}
                  error={!!errors.email}
                  helperText={errors.email?.message}
                />
              )}
            />

            {/* Password */}
            <Controller
              name="password"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  label="Password"
                  variant="standard"
                  type={showPassword ? 'text' : 'password'}
                  size="small"
                  autoComplete="new-password"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          <Icon
                            fontSize="20px"
                            name={showPassword ? 'visibility_off' : 'visibility'}
                            className="text-gray-700"
                          />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  sx={{
                    mb: 1
                  }}
                  error={!!errors.password}
                  helperText={errors.password?.message}
                />
              )}
            />
            {/* Password Strength */}
            <p className="mt-3 text-black-70">
              <Trans i18nKey="signUpPage.passwordStrengthTitle">{/* <b></b> */}</Trans>
            </p>
            <div className="mb-3 py-3">
              {Object.keys(strengthRules).map((ruleKey, ruleKeyIndex) => {
                return (
                  <div key={ruleKeyIndex}>
                    <Icon
                      className={`mr-2 basis-[32px] py-1 ${strengthRules[ruleKey] ? 'text-black-90' : 'text-black-40'}`}
                      fontSize="20px"
                      name="check"
                    />
                    <span className={`${strengthRules[ruleKey] ? 'text-black-90' : 'text-black-40'}`}>
                      {t(`signUpPage.passwordStrengthMessage.${ruleKeyIndex}`)}{' '}
                    </span>
                  </div>
                )
              })}
            </div>
            {/* { Captcha area} */}
            {isNeedCaptcha ? (
              <div className="mb-3 flex flex-row items-center justify-start">
                <Controller
                  name="captchaInput"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Captcha"
                      variant="outlined"
                      size="small"
                      sx={{
                        mr: 2
                      }}
                      error={!!errors.captcha}
                      helperText={errors.captcha?.message}
                    />
                  )}
                />
                <Captcha updateCaptchaToken={setCaptchaToken} isReGetCaptcha={isReGetCaptcha} />
              </div>
            ) : (
              ''
            )}
            <button
              className={`btn btn-orange ${isActionLoading ? 'disabled' : ''}`}
              type="submit"
              disabled={isActionLoading}
            >
              {isActionLoading ? (
                <svg
                  className="animate-spin h-4 w-4 rounded-full bg-transparent border-2 border-transparent border-opacity-50 border-r-gray-50 border-t-gray-50"
                  style={{
                    width: '24px',
                    height: '24px'
                  }}
                  viewBox="0 0 32 32"
                ></svg>
              ) : (
                <span>{t('button.signUp.0')}</span>
              )}
            </button>
            <div className="my-3 text-gray-700">
              <Divider>or</Divider>
            </div>
            <button className="mb-6 btn btn-orange btn-ghost" type="button" onClick={handleSignupWithGoogle}>
              <img
                className="mr-2"
                src="https://static.mlytics.com/images/website/google_icon.svg"
                width="24px"
                height="24px"
              />
              {t('button.signUp.1')}
            </button>
            <p className="pb-3 text-center text-gray-400 text-xs">
              <Trans
                i18nKey="signUpPage.termsAndPrivacyText"
                components={CustomTransComponents({ links: links.slice(0, 2) })}
              />
            </p>
          </FormControl>
        </form>
      </React.Fragment>
    </AuthLayout>
  )
}

const SignupPage = ({ setMessageDialogOpen, setErrorMessageI18n }) => {
  const { t } = useTranslation()
  const currentYear = new Date().getFullYear()

  const { screenM } = useBreakpoint()

  const [portalHost, setPortalHost] = useState('')

  useLayoutEffect(() => {
    if (typeof window !== 'undefined') {
      setPortalHost(getPortalHost())
    }
  })

  const SLIDER_SETTINGS = {
    fade: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    pauseOnHover: false,
    speed: 1500,
    swipe: false,
    touchMove: false,
    autoplaySpeed: 6000,
    cssEase: 'linear'
  }

  return (
    <React.Fragment>
      <Seo
        seo={{
          metaTitle: 'Signup to Mlytics',
          metaDescription: `Join Mlytics today and accelerate your application’s delivery. Enjoy free credits and fast, reliable experiences from day one—unlock global reach instantly!`,
          metaUrl: 'https://www.mlytics.com/sign-up',
          shareImage: {
            imageUrl: 'https://www.mlytics.com/wp-content/uploads/2023/03/og_v5.jpg',
            imageWidth: 1200,
            imageHeight: 630,
            imageType: 'image/jpeg'
          }
        }}
      />
      <div className="pt-3 pb-2.5 px-9 md:px-[86px] max-w-[1440px] mx-auto">
        <div>
          <div className="flex items-center justify-between">
            <Link to="/" className="shrink-0 text-xl font-medium">
              <img
                alt="Mlytics"
                src="https://www.mlytics.com/wp-content/uploads/2021/11/logo_Mlytics_regular.svg"
                height="75"
                width="140"
              />
            </Link>
            {/* <div>
              <LangSwitcher className="flex h-9 items-center" />
            </div> */}
          </div>
        </div>

        <section className="py-[80px]">
          <div className="w-full relative flex flex-col lg:flex-row items-center lg:items-start justify-between pb-[60px] gap-10">
            <div className="flex-1 w-[350px] max-w-[528px] md:w-[528px]">
              <div className="w-full flex flex-col gap-[60px]">
                <div className="flex flex-col gap-3">
                  <p className="h5-subtext-bold text-black-100">Sign up today</p>
                  <h1 className="h1-page-title text-black-100">Streamline the speedy experiences!</h1>
                </div>
                <div className="flex flex-col gap-10">
                  <div className="flex flex-col gap-6">
                    <p className="h3-card-title text-black-90">Our Pricing</p>
                    <p className="h5-subtext-medium text-black-70">
                      Discover the plans of Mlytics’s platform and start using free credits today!
                    </p>
                  </div>
                  <div className="w-full px-5">
                    <div className="w-full flex flex-col md:flex-row gap-3 border-b border-solid border-black-40">
                      <div className="flex flex-1 flex-col items-center pt-4 pb-6 gap-6">
                        <div className="w-[200px] h-[40px] overflow-hidden rounded">
                          <img src={developer} alt="developer price" />
                        </div>
                        <div className="flex flex-col gap-8 flex-1 items-center justify-between">
                          <span className="block h2-block-title text-black-90">Free</span>
                          <div>
                            <ul className="flex flex-col gap-1">
                              <li className="flex items-center gap-1">
                                <Icon fontSize="24px" name="check" className="text-black-90" />
                                <span className="block h5-subtext-medium text-black-90">5GB traffic</span>
                              </li>
                              <li className="flex items-center gap-1">
                                <Icon fontSize="24px" name="check" className="text-black-90" />
                                <span className="block h5-subtext-medium text-black-90">200k requests</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-1 flex-col items-center pt-4 pb-6 gap-6">
                        <div className="w-[200px] h-[40px] overflow-hidden rounded">
                          <img src={business} alt="business price" />
                        </div>
                        <div className="flex flex-col gap-8 flex-1 justify-between">
                          <span className="block h4-content-text text-black-90 text-center pt-2">USD 500/month</span>
                          <div>
                            <ul className="flex flex-col gap-1">
                              <li className="flex items-center gap-1">
                                <Icon fontSize="24px" name="check" className="text-black-90" />
                                <span className="block h5-subtext-medium text-black-90">1TB traffic</span>
                              </li>
                              <li className="flex items-center gap-1">
                                <Icon fontSize="24px" name="check" className="text-black-90" />
                                <span className="block h5-subtext-medium text-black-90">10 million requests</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="pt-6 flex items-center justify-center gap-3">
                      <div className="w-[120px] h-full overflow-hidden">
                        <img src={aws} alt="aws-logo" />
                      </div>
                      <div className="w-[120px] h-full overflow-hidden">
                        <img src={gma} alt="gma-logo" />
                      </div>
                      <div className="w-[120px] h-full overflow-hidden">
                        <img src={vnCdn} alt="vnCdn-logo" />
                      </div>
                    </div>
                  </div>
                  <div className="w-full flex items-center justify-end pr-5">
                    <a target="_blank" href="/pricing" className="text-black-90 h6-tiny-text-regular underline">
                      More About Pricing Plan & FAQ
                    </a>
                  </div>
                </div>
                <div className="h-full pb-[60px]">
                  <div className="border-t border-black-60 py-5">
                    <SignUpCarousel activeIndex={0} slides={slideItems} autoplay />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex-1 flex flex-col w-[320px] h-full px-3 py-5 max-w-[528px] md:w-[500px] bg-white border border-black-100 rounded-lg drop-shadow-sm md:p-10 gap-10">
              <SignupForm actionProp={{ setMessageDialogOpen, setErrorMessageI18n }} />
            </div>
          </div>
        </section>
      </div>
      <footer className="px-9 md:px-[86px] py-5 border-t border-black-20">
        <p className="h6-tiny-text-regular text-black-60">
          {t('footer.companyAnnouncementText', { year: currentYear })}
        </p>
      </footer>
    </React.Fragment>
  )
}

SignupForm.propTypes = {
  actionProp: PropTypes.object
}

SignupPage.propTypes = {
  setMessageDialogOpen: PropTypes.func,
  setErrorMessageI18n: PropTypes.func
}

const mapDispatchToProps = (dispatch) => ({
  setMessageDialogOpen: (isOpen) => dispatch({ type: 'SET_MESSAGE_DIALOG_OPEN', payload: isOpen }),
  setErrorMessageI18n: (i18nKey) => dispatch({ type: 'SET_ERROR_MESSAGE_I18N_KEY', payload: i18nKey })
})

export default connect(null, mapDispatchToProps)(SignupPage)

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
